tbody.playerinfo>tr>td>img {
    margin: auto;
}

tbody.playerinfo>tr>td {
    text-align: center;
    padding: 0.3rem;
    border-color: var(--fg-color) !important;
    border-left: 1px solid;
}

tbody.playerinfo>tr:nth-child(odd) {
    background: var(--fg-dark-color);
}

tbody.playerinfo>tr:nth-child(even) {
    background: var(--fg-dark-color);
}

img.equipment {
    height: 3rem;
    width: 3rem;
}

td.stats {
    padding: 0rem;
}

tr.stats>td>img {
    text-align: center;
    vertical-align: top;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.1rem;
    margin: 0rem;
    margin: auto;
    background-color: transparent;
}

tr.stats>td {
    border-left: 1px solid;
    text-align: center;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.1rem;
    margin: 0rem;
    margin: auto;
}

h4 {
    text-align: center;
}